import { Text, Card, CardBody, useMatchBreakpoints } from '@pancakeswap/uikit'
import styled from 'styled-components';

const StyledCard = styled(Card) <{ background: string; rotation?: string }>`
  height: fit-content;
  padding: 0px;
  box-sizing: border-box;
  /* background:${({ theme }) => theme.colors.backgroundAlt2}; */
  background: transparent;
  border-radius: 15px;
  border: none;
  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : '')}
  }
`

const StyledCardBody = styled(CardBody) <{ background: string; rotation?: string }>`
  /* background: ${({ theme }) => theme.colors.backgroundAlt2}; */
  border: 0.5px solid #263B61;
  border-radius: 20px;
`

const ExchangeStatics: React.FC<{ headingText: any; bodyText: string; highlightColor: string }> = ({
  headingText,
  bodyText
}) => {

  const { isMobile } = useMatchBreakpoints();

  return (
    <StyledCard style={{ marginTop: isMobile ? '25px' : "0px" }}>
      <StyledCardBody>
        <Text  fontSize="23px" fontWeight="600" style={{ marginBottom: "5px" }}>
          {(headingText)}
        </Text>
        <Text color="primary" fontSize="15px" >{bodyText}</Text>
      </StyledCardBody>
    </StyledCard>
  )
}

export default ExchangeStatics
